// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulkDeleteContainer[data-v-f7c5eae8] {
  padding: 24px;
  display: grid;
  gap: 14px;
}
.bulkDeleteContainer p[data-v-f7c5eae8] {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}`, "",{"version":3,"sources":["webpack://./_bulk-delete-transaction-modal.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,SAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACR","sourcesContent":[".bulkDeleteContainer {\n    padding: 24px;\n    display: grid;\n    gap: 14px;\n\n    p {\n        font-size: 20px;\n        font-weight: 400;\n        line-height: 28px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
