export default {
    props: {
        transactions: {
            type: Array,
            required: true,
        },
    },
    data: data,
    methods: getMethods(),
    computed: getComputed(),
};

function data(){
    return {
        errorMessages: [],
        isDeleting: false,
    };
}

function getComputed(){
    return {
        numberOfTransactions(){
            return this.transactions.length;
        }
    };
}

function getMethods(){
    return {
        openModal,
        bulkDeleteTransactions,
    };

    function openModal(){
        const vm = this;
        vm.$refs.bulkDeleteTransactions.show();
    }

    function bulkDeleteTransactions(){
        const vm = this;
        vm.isDeleting = true;
        let transactionsIds = vm.transactions.map((transaction) => {
            if(transaction.parent_transaction_id){
                return transaction.parent_transaction_id;
            }
            return transaction.id;
        });

        transactionsIds = transactionsIds.filter((transactionId, index) => transactionsIds.indexOf(transactionId) === index);
        const payload = {
            transaction_ids: transactionsIds,
        };
        Vue.appApi().authorized().bankAccount().bulkDeleteTransactions(payload)
            .then(closeModal)
            .catch(displayApiErrors);

        function closeModal(){
            vm.errorMessages = [];
            vm.isDeleting = false;
            vm.$emit('close');
            vm.$refs.bulkDeleteTransactions.hide();
        }

        function displayApiErrors(err){
            if(err.appMessage){
                vm.errorMessages.push(err.appMessage);
            }
        }
    }
}
